import React,{useEffect} from "react";
import { BrowserRouter, Routes, Route,Navigate } from "react-router-dom";
import Login from "./components/Login";
import Register from "./components/Register";
import ForgotPassword from "./components/ForgotPassword";
import { GoogleOAuthProvider} from '@react-oauth/google';
import { ResetPasswordWrapper } from "./components/ResetPassword/ResetPasswordWrapper";
import { NoConfirmobile } from "./components/NoConfirmobile";
import { Confirmobile } from "./components/Confirmobile";




export default function App() {
  const ip_address="https://web.bfdrive.ma"

  useEffect(()=>{
        
    check_user()

 },[])

  const check_user=async()=> {
    const config={
       headers:{
           'content-Type':'application/json'
       }
   }
const token=localStorage.getItem("access_token") || "undefined"
   if (token || token==!"undefined"){
       config.headers['Authorization']=`Bearer ${token}`
       console.log(config)



  const request=await fetch(`${ip_address}/api/auth/user`,config)
  const statut=request.status
switch(statut){
  case 200:
    console.log("user is authenticated")
    window.location.href = `${ip_address}/loading/${localStorage.getItem("access_token")}`
    break
  default:
    console.log("user not authenticated")
    localStorage.removeItem("access_token")
    break
}

    }}



  const languages = [
    {
      code: 'fr',
      name: 'Français',
      country_code: 'fr',
      dir: 'ltr',
    },
    {
      code: 'ar',
      name: 'العربية',
      dir: 'rtl',
      country_code: 'ma',
    },

    {
      code: 'en',
      name: 'English',
      dir: 'ltr',
      country_code: 'us',
    }
  ]
  // const ip_address="https://web.bfdrive.ma"
  return (
    <BrowserRouter>
    {/* <GoogleOAuthProvider clientId="901171606422-gs4vgig6f37bl8pmpmat1agjjjaviaim.apps.googleusercontent.com"> */}
      <Routes>
        {/* <Route path="/" element={<Layout />}> */}
          <Route path="login" element={<Login />} />
          <Route path="register" element={<Register />} />
          <Route path="recovery" element={<ForgotPassword  languages={languages} ip_address={ip_address}/>} />
          <Route path="reset_password/:token" element={<ResetPasswordWrapper languages={languages} ip_address={ip_address}/>} />
          <Route path="confirmobile" element={<Confirmobile />} />
          <Route path="noconfirmobile" element={<NoConfirmobile />} />
          <Route path="*" element={<Navigate to="/login" />} />

          {/* <Route path="*" element={<NoPage />} /> */}
        {/* </Route> */}
      </Routes>
      {/* </GoogleOAuthProvider> */}
    </BrowserRouter>
  );
}