import React,{useState} from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { LanguageFeature } from './LanguageFeature'

const ForgotPassword = ({ip_address,languages}) => {
  const {t}=useTranslation()
  const [email, setEmail] = useState('');

  const handleResetPassword = (e) => {
    e.preventDefault()
    // Perform validation on the email (you can use a library like 'validator' for this)
    if (!email) {
      alert('Please enter your email address.');
      return;
    }

    // Here you can make an API call to your server to send the password reset email
    // Replace 'YOUR_API_ENDPOINT' with the actual endpoint for password reset in your server
    fetch(`${ip_address}/api/password_reset/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response from the server, e.g., show a success message or an error message
       

        console.log(data)
        if(Object.keys(data).includes("status")){
          if(data.status==="OK"){
            alert(`check your mailBox at\n ${email}`);    
          }
        }else if(Object.keys(data).includes("email")){
         
            alert(`${data.email}`);
         
        }
      })
      .catch((error) => {
        // Handle any errors that occurred during the API call
        console.error('Error:', error);
      });
  };
  return (
    <div className="container">
    
    <div className='login_container'>
      
      <div className='element'>
    <img src={`${ip_address}/media/logo.png`} id='logo' style={{width:120,height:120,marginTop:-30,marginBottom:0}}/>
    <form onSubmit={handleResetPassword} style={{width:"80%"}}>
    <div className='mb-2 dFlex' id="Help" style={{fontSize:14,textAlign:"center",color:"gray"}}>{t("Please enter your email address to receive a password reset link.")}</div>
    <div className="form-group">
        <input
          type="email"
          class="form-control"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          placeholder={t('Email')}
        />
      </div>
      <button class="btn register" type="submit" style={{width:"100%",backgroundColor:"#ff5d70",color:"white",fontFamily: 'Airstrike'}}>{t("Submit")}</button>

    </form>
    <Link to={"/login"} style={{margin:10,fontSize:15,color:"blue"}}>{t("Back To Login")}</Link>
    </div>
    <LanguageFeature languages={languages}/>
    </div>
  </div>
  )
}

export default ForgotPassword