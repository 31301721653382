import React from 'react'
import { useTranslation } from 'react-i18next'; 


export const Confirmobile = () => {

  const {t}=useTranslation()

  
  return (
    <div style={{display:'flex',justifyContent:'center',alignItems:'center',height:'700px',backgroundColor: '#f2f2f2'}}>
    <div class="email_container" style={{
  maxWidth: '500px',
  backgroundColor: '#ffffff',
  borderRadius: '5px',
  padding: '20px',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
}}>

    <div className='row'>
        <div className='col-8'>
            <h1 style={{fontFamily: 'Airstrike'}}>{t("Confirm Subscription")}</h1>
        </div>
        <div className='col-4' style={{}}>
            <img src={`/Logo.svg`} id='logo' style={{width:'100px',marginTop:'10px'}}/>
        </div>
    </div>
    
    <p style={{     marginBottom: '10px',
      lineHeight: '1.5'}}>{t("Your Email has been successfully confirmed")}</p>
    <p>{t("Please reload your mobile app to Authenticate")}</p>

  </div>
  </div>
  )
}
