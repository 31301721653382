import React,{useState} from 'react'
import { useTranslation } from 'react-i18next';
import { Link,useNavigate } from 'react-router-dom';
import { IconContext } from "react-icons"
import { LanguageFeature } from '../LanguageFeature'
import { toggleVisibility,setEye } from '../Register'


export const ResetPassword = ({ token, ip_address ,languages}) => {
    const {t}=useTranslation()
    const [password, setPassword] = useState()
    const [password_conf, setPassword_conf] = useState()
    const [valid,setValid]=useState(false)
    const [confirm,setConfirm]=useState(false)
    const [visibilité1, setVisibilité1] = useState('password')
    const [visibilité, setVisibilité] = useState('password')
    const navigate = useNavigate()

    const sendInputValues = async (e) => {
        e.preventDefault()
    if(password === password_conf){    const formdata = new FormData()
        formdata.append('password', password)
        formdata.append('token', token)
        const request = await fetch(`${ip_address}/api/password_reset/confirm/`, {
            method: 'POST',
            body: formdata
        })
        switch (request.status) {
            case 200:
                const response = await request.json()
                navigate('/')
            default:
                const response2 = await request.json()
                alert(`please try again \n ${response2.password}`)

        }}else{
            alert('passwords does not mutch')
        }



    }

    const handlePassword=(e)=>{
        const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        const isValid = passwordPattern.test(e.target.value); 
        setPassword(e.target.value)
        setValid(isValid)   
      }

      const handlePasswordConfirm=(e)=>{
        setPassword_conf(e.target.value)
        switch(e.target.value){
          case password:
            setConfirm(true)
            break
          default:
            setConfirm(false)
            break
        }
     
      }
  return (
    <div className="container">
    
    <div className='login_container'>
      
      <div className='element'>
    <img src={`${ip_address}/media/logo.png`} id='logo' style={{width:120,height:120,marginTop:-30,marginBottom:0}}/>
    <form onSubmit={sendInputValues} style={{width:"80%"}}>
    <div class="input-group mb-3">
<input type={visibilité} className="form-control" 
  id="confirm password" 
  style={confirm ? { color: "green" } : { color: "red" }} 
  onChange={(e) => handlePassword(e)} value={password} 
  placeholder={t('New Password')} required />
  <span class="input-group-text" onClick={()=>toggleVisibility(visibilité, setVisibilité)}>
  <IconContext.Provider value={{ style: { fontSize: "17px",color:"red"}, className: "global-class-name" }}>
                    <div>
                    {setEye(visibilité)}
                    </div>
                  </IconContext.Provider>
               
  </span>

</div>
    <div class="input-group mb-3">
<input type={visibilité1} className="form-control" 
  id="confirm password" 
  style={confirm ? { color: "green" } : { color: "red" }} 
  onChange={(e) => handlePasswordConfirm(e)} value={password_conf} 
  placeholder={t('Confirm Password')} required />
  <span class="input-group-text" onClick={()=>toggleVisibility(visibilité1, setVisibilité1)}>
  <IconContext.Provider value={{ style: { fontSize: "17px",color:"red"}, className: "global-class-name" }}>
                    <div>
                    {setEye(visibilité1)}
                    </div>
                  </IconContext.Provider>
               
  </span>

</div>

      <button class="btn" type="submit" style={{width:"100%",backgroundColor:"#ff5d70",color:"white",fontFamily: 'Airstrike'}}>{t("Submit")}</button>

    </form>
    <Link to={"/"} style={{fontFamily:'Airstrike',fontSize:15,margin:10,color:"blue"}}>{t("Back To Login")}</Link>
    </div>
    <LanguageFeature languages={languages}/>
    </div>
  </div>
  )
}
