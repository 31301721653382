import React from 'react'
import { useParams } from 'react-router-dom'
import { ResetPassword } from './ResetPassword'

export const ResetPasswordWrapper = ({ip_address,languages}) => {
    const {token}=useParams()
  return (
    <ResetPassword token={token} ip_address={ip_address} languages={languages}/>
  )
}
