import React,{useEffect, useState,useCallback} from 'react'
import './css/bootstrap.min.css'
import './LoginIn.css'
import { IconContext } from "react-icons"
import { Link ,useNavigate} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { toggleVisibility,setEye } from './Register'
import {LoginSocialGoogle} from 'reactjs-social-login'
import {GoogleLoginButton} from 'react-social-login-buttons'
import { LanguageFeature } from './LanguageFeature'
import { withGoogleReCaptcha,GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';


const Login1 = ({ googleReCaptchaProps}) => {
  const languages = [
    {
      code: 'fr',
      name: 'Français',
      country_code: 'fr',
      dir: 'ltr',
    },
    {
      code: 'ar',
      name: 'العربية',
      dir: 'rtl',
      country_code: 'ma',
    },

    {
      code: 'en',
      name: 'english',
      dir: 'ltr',
      country_code: 'us',
    }
  ]
  const ip_address="https://web.bfdrive.ma"
    const navigate=useNavigate()
    const {t}=useTranslation()
    const [email,setEmail]=useState('')
    const [password,setPassword]=useState('')
    const [visibilité1, setVisibilité1] = useState('password')
    const [captchaValue, setCaptchaValue] = useState("")
    const [token, setToken] = useState("");
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

    useEffect(() => {
      const getToken = async () => {
        try {
          const token = await googleReCaptchaProps.executeRecaptcha("homepage");
          setCaptchaValue(token);
        } catch (error) {
          console.error("Error executing reCAPTCHA:", error);
        }
      };
      getToken();
    }, [googleReCaptchaProps]);
// google login
    const new_google_login=async(accesstoken,email)=> {
        const formdata=new FormData()
        formdata.append('token',accesstoken)
        formdata.append('app_name','normal_login')
        formdata.append('email',email)
    const request=await fetch(`${ip_address}/api/auth/googleLogin`,{
        method:'POST',
        body: formdata,
     })
    
     console.log(request)
    const res=await request.json()
    console.log(res)
     switch(request.status){
        case 200:
          localStorage.setItem("access_token",res.access_token)
          setTimeout(window.location.href = `${ip_address}/loading/${res.access_token}`,500)
     break
     default:
        alert('user does not exist')
        break
     } }

    // norma login

    const new_login=async(data)=>{
        const request=await fetch(`${ip_address}/api/auth/login`,{
          method:'POST',
          body: data,
         
      
       })
      const res=await request.json()
      console.log(res)
       switch(request.status){
          case 200:
            localStorage.setItem("access_token",res.access_token)
            setTimeout(window.location.href = `${ip_address}/loading/${res.access_token}`,500)
      console.log(res)
       break
       default:
          alert('wrong credentials')
          navigate('/')
          break
       }
      
      }

// on submit 
const onSubmit=async (e)=>{
    e.preventDefault()
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/
    
    if(1){ 

    const formdata=new FormData()
    formdata.append("email",email)
    formdata.append("password",password)
    formdata.append("grant_type",'password')
    formdata.append("client_id",'v5uOuy0AFs80jOqAuprtL8cHu4LVp1inikA8eJHx')
    formdata.append("client_secret","GhL2IHjfLVXFnYdS8BR1UBipLa4FomC2AaIeNrYHCxlxYomnEr7JhUGdtFgOx0kjXcCAomF7cxudY7k6VJNgCWvIvsbj8A9PQXCgJr4fFAUJijHb8KWp59JkaJJYqyoB")
    new_login(formdata)
    setTimeout(navigate('/timeline'),500)// a changer
  }else{
    alert(t("check captcha")
         )
  }
    
   
  }
      
  const loginFunction=(response)=>{
    // console.log(response)
    // const userObject=jwt_decode(response.credential)
    // console.log(userObject)
    localStorage.setItem('googlePic',response.picture)
    new_google_login(response.access_token,response.email)
  
  }




  return (

    <div className="container">
    
    <div className='login_container'>
      
      <div className='element'>
    <img src={`${ip_address}/media/logo.png`} id='logo' style={{width:120,height:120,marginTop:-30,marginBottom:0}}/>
    <form onSubmit={onSubmit} style={{width:"80%"}}>
      <div className="form-group">
      

       
        <input
          type="email"
          class="form-control"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          placeholder={t('Email')}
        />
      </div>
      <div class="input-group mb-3">

  <input type={visibilité1} class="form-control" 
  placeholder={t("Password")} value={password}
  onChange={(e)=>setPassword(e.target.value)}/>
    <span class="input-group-text" onClick={()=>toggleVisibility(visibilité1, setVisibilité1)}>
  <IconContext.Provider value={{ style: { fontSize: "17px",color:"red"}, className: "global-class-name" }}>
                    <div>
                    {setEye(visibilité1)}
                    </div>
                  </IconContext.Provider>
               
  </span>
</div>
      
<div className={`dFlex`}>
<Link to='/recovery' style={{textDecoration:"none",ffontSize:15,color:"blue"}}>{t("Forgot password ?")} </Link>
</div>
{/* <GoogleReCaptchaProvider reCaptchaKey="6LekPYIpAAAAAN8NRgXrNYdVtML9BWW9UzL90RIQ">
    <GoogleReCaptcha onVerify={onVerify} />
  </GoogleReCaptchaProvider> */}
  <button class="btn login" type="submit" style={{width:"100%",backgroundColor:"#df1d34",color:"white",fontFamily: 'Airstrike',marginTop:10}}>{t("Log in")}</button>
  
 
  <div style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center"}}>
  <div style={{borderBottom:"solid gray 2px",marginTop:0,width:"40%"}}>
    
    </div>
    <div style={{width:"10%",margin:"auto"}}>
    OR
  </div>
    <div style={{borderBottom:"solid gray 2px",marginTop:0,width:"40%"}}>
    
  </div>
  </div>
 
  <LoginSocialGoogle
          client_id="440145441936-cad9sapqd83lsuu8fqavfohechb032ln.apps.googleusercontent.com"          // onLoginStart={onLoginStart}
          // redirect_uri={REDIRECT_URI}
          scope="openid profile email"
          // discoveryDocs="claims_supported"
          // access_type="offline"
          onResolve={({ provider,data}) => {
            console.log({ provider,data})
            loginFunction(data)
          }}
          onReject={err => {
            console.log('google error',err);
          }}
        >
<GoogleLoginButton >
<span style={{width:100,textAlign:"center"}}>{t("Login with google")}</span>
          </GoogleLoginButton >
        </LoginSocialGoogle>
<h6 style={{  display:"flex",
  flexDirection:"row",
  alignItems:"center",
  justifyContent:"center",
  marginTop:12,
  marginBottom:12}}>{t("Don't have an account ?")}</h6>
<button className='btn register' onClick={()=>navigate('/register')} style={{width:"100%",backgroundColor:"#ff5d70",color:"white",fontFamily: 'Airstrike'}}>{t("Register")}</button>

    </form>
    </div>
    <LanguageFeature languages={languages}/>
    </div>
  </div>
);
};

const Login2 = withGoogleReCaptcha(Login1)

const Login=()=> {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LdjndgoAAAAAEYu0fB4KiljRUlxVzsAMKeUF5CV">
      <Login2 />
    </GoogleReCaptchaProvider>
  );
}

export default Login;