import React,{useEffect, useState,useCallback} from 'react'
import './css/bootstrap.min.css'
import "./LoginIn.css"
import { IconContext } from "react-icons"
import { Link ,useNavigate} from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {LoginSocialGoogle} from 'reactjs-social-login'
import {GoogleLoginButton} from 'react-social-login-buttons'
import {FiEye,FiEyeOff} from 'react-icons/fi'
import { LanguageFeature } from './LanguageFeature'
import { withGoogleReCaptcha,GoogleReCaptchaProvider} from 'react-google-recaptcha-v3';


export const toggleVisibility=(pass,setPass)=>{
    if(pass==='password'){
      console.log(pass)
      setPass('text')
    }
    if(pass==='text'){
      console.log(pass)
      setPass('password')
    }
  }
  
  export const setEye=(visibilité)=>{
    switch(visibilité){
      case 'text':
        return <FiEye/>
      case 'password':
        return <FiEyeOff />
    }
  }
  
 const Register1 = ({ googleReCaptchaProps}) => {
  const languages = [
    {
      code: 'fr',
      name: 'Français',
      country_code: 'fr',
      dir: 'ltr',
    },
    {
      code: 'ar',
      name: 'العربية',
      dir: 'rtl',
      country_code: 'ma',
    },

    {
      code: 'en',
      name: 'english',
      dir: 'ltr',
      country_code: 'us',
    }
  ]
  const ip_address="https://web.bfdrive.ma"
    const { t } = useTranslation()
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [phone_id, setPhone_id] = useState('')
    const [password, setPassword] = useState('')
    const [captchaValue, setCaptchaValue] = useState(null)
    const [visibilité1, setVisibilité1] = useState('password')
    const [visibilité2, setVisibilité2] = useState('password')
    const [valid, setValid] = useState(false)
    const [isValid, setIsValid] = useState(true);
    const [confirm, setConfirm] = useState(false)
    const [token, setToken] = useState(null);
    const [password_confirmation, setPassword_confirmation] = useState('')
    const navigate = useNavigate()
   
      //normal register


      useEffect(() => {
        const getToken = async () => {
          try {
            const token = await googleReCaptchaProps.executeRecaptcha("homepage");
            setCaptchaValue(token);
          } catch (error) {
            console.error("Error executing reCAPTCHA:", error);
          }
        };
        getToken();
      }, [googleReCaptchaProps]);

      

const new_register=async(data)=>{
    const requset =await fetch(`${ip_address}/api/auth/register`,{
      method:'POST',
      body: data,
     
  
   })
  const res=await requset.json()
   switch(requset.status){
      case 200:
        window.location.href = `https://web.bfdrive.ma/loading/${res.access_token}`
        break
      case 400:
        alert(res[0])
          break
   }
  }
  
  
    // google register
    const new_ggogle_register=async(accesstoken,email,username)=>{
      
      const formdata=new FormData()
      formdata.append('token',accesstoken)
      formdata.append('username',username)
      formdata.append('app_name','google')
      formdata.append('email',email)
     
  const request=await fetch(`${ip_address}/api/auth/googleRegister`,{
      method:'POST',
      body: formdata,
     
  
   })
  
   console.log(request)
  const res=await request.json()
  console.log(res)
   switch(request.status){
      case 200:
        window.location.href = `https://web.bfdrive.ma/loading/${res.access_token}`
   break
   default:
      alert(res[0])
      break
   } }


   const registerFunction=(response)=>{

    localStorage.setItem('googlePic',response.picture)
    new_ggogle_register(response.access_token,response.email,response.name.replace(" ","_"))
  
  }
   const onSubmit_register = async (e) => {
    e.preventDefault()
    if(1){
     const formdata = new FormData()
     formdata.append("username", `${firstname}_${lastname}_${phone}`)
     formdata.append("password", password)
     formdata.append("phone", phone_id+phone)
     formdata.append("firstname", firstname)
     formdata.append("lastname", lastname)
     formdata.append("email", email)
     formdata.append("password_confirmation", password_confirmation)
     if(confirm && isValid){new_register(formdata)
               navigate('/register1')
               }else{if(!confirm){alert(t('Passwords don\'t not much'))}else if(!!isValid){alert(t('Phone number must contain only nine digits'))}
                 
               }}else{
                alert(t("Check captcha"))
               }
   }
 
   const handlePassword = (e) => {
     const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
     const isPass = passwordPattern.test(e.target.value);
     setPassword(e.target.value)
     setValid(isPass)
   }
 
 
   const handlePasswordConfirm = (e) => {
     setPassword_confirmation(e.target.value)
     switch (e.target.value) {
       case password:
         setConfirm(true)
         
         break
       default:
         setConfirm(false)
         break
     }
 
   }
   const handlePhoneNumberInput = (e) => {
     const inputValue = e.target.value;
     // Define a regular expression for a valid phone number format
     const phoneRegex = /^\d{9}$/; // This example assumes a 10-digit phone number
 
     // Check if the input value matches the regular expression
     const isValidInput = phoneRegex.test(inputValue);
 
     // Update the state based on whether the input is valid
     setIsValid(isValidInput);
     setPhone(inputValue);
   };
  return (
    <div className="container">
    
    <div className='login_container'>
      
      <div className='element'>
    <img src={`${ip_address}/media/logo.png`} id='logo' style={{width:120,height:120,marginTop:-30,marginBottom:0}}/>
    <form  style={{width:"80%"}}>

    <div class="row mb-2">
  <div class="col">
  <input
          type="text"
          class="form-control"
          value={firstname}
          onChange={(e) => setFirstname(e.target.value)}
          required
          placeholder={t('Firstname')}
        />
  </div>
  <div class="col">
  <input
          type="text"
          class="form-control"
          value={lastname}
          onChange={(e) => setLastname(e.target.value)}
          required
          placeholder={t('Lastname')}
        />
  </div>
</div>
    {/* <div className="form-group">
      
     
      </div>

      <div className="form-group">
  
      </div> */}



      <div className="form-group mb-1">
        <input
          type="email"
          class="form-control"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          placeholder={t('Email')}
        />
      </div>

      <div class="input-group">
      <span class="input-group-text">
 +212
               
  </span>
<input type="tel" className="form-control" 
  id="confirm password" 
  style={isValid ? { color: "green" } : { color: "red" }} 
  onChange={(e) =>handlePhoneNumberInput(e)} value={phone} 
  placeholder={t("Phone Number")} required />


</div>

{phone? (!isValid ? <div className='mb-2 dFlex' id="Help" style={{ color: "red",fontSize:10 }}>{t("Phone number is not valid")}</div> : <div id="Help" class="mb-2 dFlex" style={{ color: "green",fontSize:10 }}>{t("Phone number is valid")}</div>):<div id="Help" class="mb-2 dFlex" style={{ color: "gray",fontSize:10 }}>{t("Must containe nine digits")}</div>}

      <div class="input-group">
      <input type={visibilité1} class="form-control" 
  placeholder={t("Password")} value={password}
  onChange={(e)=>handlePassword(e)}
  required/>
  <span class="input-group-text" onClick={()=>toggleVisibility(visibilité1, setVisibilité1)}>
  <IconContext.Provider value={{ style: { fontSize: "17px",color:"red"}, className: "global-class-name" }}>
                    <div>
                    {setEye(visibilité1)}
                    </div>
                  </IconContext.Provider>
               
  </span>
  
</div>
{password? (!valid ? <div className='mb-2 dFlex' id="Help" style={{ color: "red",fontSize:10 }}>{t("Password is week")}</div> : <div id="Help" class="mb-2 dFlex" style={{ color: "green",fontSize:10 }}>{t("Password is valid")}</div>):<div id="Help" class="mb-2 dFlex" style={{ color: "gray",fontSize:10 }}>{t("Upper,lower case,digits and special caracters(!, @, #, $ ...)")}</div>}
   
<div class="input-group">
<input type={visibilité2} className="form-control" 
  id="confirm password" 
  style={confirm ? { color: "green" } : { color: "red" }} 
  onChange={(e) => handlePasswordConfirm(e)} value={password_confirmation} 
  placeholder={t('Confirm Password')} required />
  <span class="input-group-text" onClick={()=>toggleVisibility(visibilité2, setVisibilité2)}>
  <IconContext.Provider value={{ style: { fontSize: "17px",color:"red"}, className: "global-class-name" }}>
                    <div>
                    {setEye(visibilité2)}
                    </div>
                  </IconContext.Provider>
               
  </span>

</div>
{password_confirmation? (!confirm ? <div className='mb-3 dFlex' id="Help" style={{ color: "red",fontSize:10 }}>{t("Password does not mutch")}</div> : <div id="Help" class="mb-3 dFlex" style={{ color: "green",fontSize:10 }}>{t("Password mutches")}</div>):<div className='mb-3'></div>}

<div style={{  display:"flex",
  flexDirection:"column",
  alignItems:"center",
  justifyContent:"center",
  marginBottom:10
  }}>
 <span style={{marginBottom:-10,color:"red"}}>{t("By creating an account you agree to the")}</span>
 <div style={{  display:"flex",
  flexDirection:"row",
  alignItems:"center",
  justifyContent:"center"}}>
 <a href='https://bfdrive.ma/Processing-data.html' class="form-text" style={{fontSize:13,marginInline:2,fontWeight:"bold" }}> {t("Processing of personal data")}</a><div style={{fontFamily:"Airstrike",fontSize:20}}> & </div>  <a href='https://bfdrive.ma/terms.html' class="form-text" style={{fontSize:13,marginInline:3,fontWeight:"bold" }}> {t("Terms of use")}</a>
</div></div>

{/* <GoogleReCaptchaProvider reCaptchaKey="6LdjndgoAAAAAEYu0fB4KiljRUlxVzsAMKeUF5CV"> */}
{/* <GoogleReCaptchaProvider reCaptchaKey="6LekPYIpAAAAAN8NRgXrNYdVtML9BWW9UzL90RIQ">
    <GoogleReCaptcha onVerify={handleRecaptcha} />
  </GoogleReCaptchaProvider> */}
  <button class="btn register" onClick={onSubmit_register} style={{width:"100%",backgroundColor:"#ff5d70",color:"white",fontFamily: 'Airstrike'}}>{t("CREATE ACCOUNT")}</button>
  <div style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center"}}>
  <div style={{borderBottom:"solid gray 2px",marginTop:0,width:"40%"}}>
    
    </div>
    <div style={{width:"10%",margin:"auto"}}>
    OR
  </div>
    <div style={{borderBottom:"solid gray 2px",marginTop:0,width:"40%"}}>
    
  </div>
  </div>
  <LoginSocialGoogle
          client_id="440145441936-cad9sapqd83lsuu8fqavfohechb032ln.apps.googleusercontent.com"          // onLoginStart={onLoginStart}
          // redirect_uri={REDIRECT_URI}
          scope="openid profile email"
          // discoveryDocs="claims_supported"
          // access_type="offline"
          onResolve={({ provider,data}) => {
            console.log({ provider,data})
            registerFunction(data)
          }}
          onReject={err => {
            console.log('google error',err);
          }}
        >
          <GoogleLoginButton >
<span>{t("Register with google")}</span>
          </GoogleLoginButton >
        </LoginSocialGoogle>
<div style={{  display:"flex",
  flexDirection:"row",
  alignItems:"center",
  justifyContent:"center",
  marginTop:10}}><span>{t("Have already an account ?")} </span><Link to={"/login"} style={{color:"blue"}}>{t("Log In")}</Link></div>
 


        </form>
    </div>
    <LanguageFeature languages={languages}/>
    </div>
  </div>
  )
}

const Register2 = withGoogleReCaptcha(Register1)

const Register=()=> {
  return (
    <GoogleReCaptchaProvider reCaptchaKey="6LdjndgoAAAAAEYu0fB4KiljRUlxVzsAMKeUF5CV">
      <Register2 />
    </GoogleReCaptchaProvider>
  );
}

export default Register;