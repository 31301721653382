import React,{useEffect, useState} from 'react'
import i18next from "i18next";
import cookies from 'js-cookie'
import Dropdown from 'react-bootstrap/Dropdown';
import "/node_modules/flag-icons/css/flag-icons.min.css";

export const LanguageFeature = ({languages}) => {
    const [currentLanguageCode,setCureentLanguagesCode]=useState(cookies.get('i18next') || 'en')
    const currentLanguage=languages.find(l => l.code===currentLanguageCode)
    useEffect(()=>{
      document.body.dir=currentLanguage.dir || 'ltr'
      console.log(currentLanguage)
  
     },[currentLanguage])

     function CustomDropdownToggle({ children, onClick }) {
      return (
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
          {children}
        </Dropdown.Toggle>
      );
    }
    
    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
      <a
        href=""
        ref={ref}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}

        style={{color:"gray"}}
      >
        {children}
        &#x25bc;
      </a>
    ));

  return (
    <div style={{display:'flex',alignItems:'center',justifyContent:'center',borderRadius:5,padding:5,color:"gray",marginTop:5}}>


<Dropdown>
      <CustomDropdownToggle>
      <span className={`fi fi-${currentLanguage.country_code}`} style={{"marginRight":"auto",fontSize:20,borderRadius:"50%"}}></span>{currentLanguage.name}
      </CustomDropdownToggle>

      <Dropdown.Menu>
      {languages.map(({ code, name, country_code }) => (
        <Dropdown.Item>
          <a  onClick={() => {
            i18next.changeLanguage(code);
            setCureentLanguagesCode(code)
            }} style={{"cursor":"pointer"}}>
              <span className={`fi fi-${country_code}`} style={{"marginRight":"auto",fontSize:20,borderRadius:"50%"}}></span> {name}
          </a>
        </Dropdown.Item>
      ))}

      </Dropdown.Menu>
    </Dropdown>

    </div>
  )
}
